/*.click-to-call {
  background: #eee;
  padding: 10px;
  color: black;
  border-radius: 5px;
  font-size: 21px;
}*/
#mobile-display {
  font-size: small;
}

.click-to-call img {
  float: left;
  margin-right: 4px;
  width: 36px;
  margin-top: -2px;
}

.py-3.mx-auto.text-center.max-w-7xl {
  height: 110px;
}

.text-gray {
  font-size: 0.7rem;
  color: #b7b7b8;
}

.breadcrumb-bg {
  background: linear-gradient(90deg, #fff 0, #18d1cc) !important;
}

@media only screen and (max-width: 1024px) {
  form.mt-10.mb-4 {
    width: 100%;
    margin: auto;
    padding-top: 30px;
  }

  .bg-white.border-b.border-gray-200.rounded-lg {
    width: 60%;
    float: right;
    margin-right: 4%;
    margin-bottom: 10%;
  }

  .phone-holder {
    margin-right: 10%;
  }
}
@media only screen and (min-width: 1024px) and (max-height: 1366px) {
  .phone-holder {
    margin-right: 4%;
  }
}
@media only screen and (min-width: 1400px) and (max-height: 1800px) {
  .phone-holder {
    margin-right: 10%;
  }
}
@media only screen and (min-width: 1920px) {
  .phone-holder {
    margin-right: 18%;
  }
}
/* ----------- iPad Pro ----------- */
/* Portrait and Landscape */
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
  form.mt-10.mb-4 {
    width: 100%;
    margin: auto;
    padding-top: 30px;
  }

  .bg-white.border-b.border-gray-200.rounded-lg {
    width: 100%;
    margin-right: 4%;
    margin-bottom: 10%;
    margin-left: -13rem;
  }

  .phone-holder {
    margin-right: 18%;
  }

  body nav div.phone-holder {
    line-height: 1.2;
  }

  nav div.phone-holder .custom-design-svg {
    margin-bottom: 0.4rem !important;
  }

  .custom-flex {
    margin-right: 16%;
  }
}
@media only screen and (max-width: 1023px) {
  form.mt-10.mb-4 {
    width: 100%;
    margin: auto;
    padding-top: 30px;
  }

  .phone-holder {
    margin-right: 7%;
  }

  nav div.phone-holder .custom-design-svg {
    margin-bottom: 0.4rem !important;
    height: 1.5em;
    width: 1.5em;
  }

  body nav div.phone-holder {
    line-height: 1;
  }

  .phone-holder .font-size-custom {
    font-size: 1.7rem;
  }

  nav div.phone-holder .text-base-custom {
    font-size: 0.9rem;
  }

  .custom-flex {
    display: flex;
  }
}
@media only screen and (max-width: 1000px) {
  /*    .bg-white.border-b.border-gray-200.rounded-lg {
        width: 100%;
        float: left;
    }*/
  /*    form.mt-10.mb-4 {
        width: 60%;
        margin: auto;
        padding-top: 30px;
    }*/
  .phone-holder {
    margin-right: 0.3%;
  }
}
@media only screen and (max-width: 860px) {
  .flex.items-baseline.ml-10 {
    margin-left: 0px;
  }

  .phone-holder {
    margin-right: 0.3%;
  }
}
@media only screen and (max-width: 850px) {
  .inline-flex button.inline-flex.items-center.px-2 {
    font-size: 22px;
  }

  form.mt-10.mb-4 {
    width: 100%;
    margin: auto;
    padding-top: 30px;
  }

  bg-white.border-b.border-gray-200.rounded-lg {
    width: 70%;
    margin-right: 4%;
    margin-bottom: 10%;
  }

  .phone-holder {
    margin-right: 0.3%;
  }
}
@media only screen and (max-width: 833px) {
  .mb-2.text-xl.font-bold.text-black {
    margin-top: 5px;
  }

  .phone-holder {
    margin-right: 7.3%;
  }
}
@media only screen and (max-width: 790px) {
  .inline-flex button.inline-flex.items-center.px-2 {
    font-size: 18px;
  }

  .bg-white.border-b.border-gray-200.rounded-lg {
    width: 80%;
    margin-right: 10%;
    margin-bottom: 10%;
  }

  form.mt-10.mb-4 {
    width: 100%;
    margin: auto;
    padding-top: 30px;
  }

  .hero-image-ov {
    background: linear-gradient(90deg, #fff 0, #18d1cc) !important;
  }

  .custom-padding {
    margin-left: auto !important;
    padding-right: 3rem;
  }
}
@media only screen and (max-width: 750px) {
  form.mt-10.mb-4 {
    width: 100%;
    margin: auto;
    padding-top: 30px;
  }

  .flex.items-baseline.ml-10 {
    margin-left: 2.5rem;
  }

  .hero-image-ov {
    background: linear-gradient(90deg, #fff 0, #cbe2f5) !important;
  }

  .custom-padding {
    margin-left: auto !important;
    padding-right: 3rem;
  }

  .custom-padding {
    margin-left: auto !important;
    padding-right: 3rem;
  }
}
@media only screen and (max-width: 650px) {
  .bg-white.border-b.border-gray-200.rounded-lg {
    width: 80%;
    margin-left: 0%;
  }

  form.mt-10.mb-4 {
    width: 100%;
    margin: auto;
    padding-top: 30px;
  }

  .flex.items-baseline.ml-10 {
    margin-left: 2.5rem;
  }

  .hero-image-ov {
    background: linear-gradient(90deg, #fff 0, #cbe2f5) !important;
  }

  .custom-padding {
    margin-left: auto !important;
    padding-right: 2.7rem;
  }
}
@media only screen and (max-width: 640px) {
  .custom-padding {
    padding-right: 4rem;
  }
}
@media only screen and (max-width: 600px) {
  .bg-white.border-b.border-gray-200.rounded-lg {
    width: 90%;
    float: left;
    margin-left: 5%;
  }

  form.mt-10.mb-4 .col-sm-10 {
    max-width: 100%;
  }

  form.mt-10.mb-4 {
    width: 100%;
    margin: auto;
    padding-top: 30px;
  }

  .flex.items-baseline.ml-10 {
    margin-left: 2.5rem;
  }

  .hero-image-ov {
    background: linear-gradient(90deg, #fff 0, #cbe2f5) !important;
  }

  nav div.phone-holder .custom-design-svg {
    height: 1.3em;
    width: 1.3em;
  }

  nav div.phone-holder span .text-3xl {
    font-size: 1.5rem;
  }

  nav div.phone-holder .text-base-custom {
    font-size: 0.8rem;
  }

  body nav div.phone-holder {
    line-height: 1;
  }

  .phone-holder {
    margin-right: 0%;
    margin-bottom: 0.8rem;
  }

  .custom-padding {
    padding-right: 2rem;
  }
}
@media only screen and (max-width: 550px) {
  .bg-white.border-b.border-gray-200.rounded-lg {
    width: 100%;
    float: left;
    margin-left: 0%;
  }

  .hero-image-ov {
    background: linear-gradient(90deg, #fff 0, #cbe2f5) !important;
  }

  .custom-padding {
    padding-right: 0.2rem;
  }
}
@media only screen and (max-width: 500px) {
  .bg-white.border-b.border-gray-200.rounded-lg {
    width: 100%;
    float: left;
    margin-left: 0%;
  }

  .mb-2.text-xl.font-bold.text-black {
    margin-top: -10px;
  }

  .welcome .mb-2.text-xl.font-bold.text-black {
    margin-top: 5px;
  }

  .form-group .phone2 {
    float: left;
  }

  form.mt-10.mb-4 p.text-2xl {
    margin-left: 10px !important;
  }

  button .click-to-call {
    font-weight: 700 !important;
  }

  .py-3 .px-4.mx-auto {
    margin-left: 2px;
    padding-left: 2px !important;
    padding-right: 5px !important;
  }

  .click-to-call img {
    /*width: 130px*/
  }

  .flex.items-baseline.ml-10 {
    margin-left: 2.5rem;
  }

  .hero-image-ov {
    background: linear-gradient(90deg, #fff 0, #cbe2f5) !important;
  }

  .custom-padding {
    padding-right: 0.2rem;
  }
}
@media only screen and (max-width: 360px) {
  img.w-48 {
    width: 9rem;
  }

  span.click-to-call {
    padding: 3px 2px;
    font-size: 15px;
  }
}
@media only screen and (max-width: 340px) {
  span.click-to-call {
    font-size: 12px;
  }
}
@media only screen and (max-width: 310px) {
  span.click-to-call {
    padding: 2px;
    font-size: 11px;
  }
}
@media only screen and (max-width: 300px) {
  img.w-48.p-2 {
    padding: 2px !important;
  }

  span.click-to-call {
    padding: 3px;
    font-size: 10px;
  }
}
@media (min-width: 576px) {
  form.mt-10.mb-4 .col-sm-10 {
    max-width: 100%;
  }
}
@media (min-width: 451px) and (max-width: 690px) {
  .notsetcenter {
    text-align: center;
  }
}
.bg-orange, .orange {
  background-color: #ff9700 !important;
}

.orange:hover {
  background-color: #d99430;
}

.text-orange {
  color: #ff9700;
}

.radial-progress-container svg circle:nth-child(3) {
  stroke: #ff9700;
}